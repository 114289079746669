/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:6e9d30f5-39fa-4ae5-a1ea-54c8b704e82e",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_8Y2IHfgoM",
    "aws_user_pools_web_client_id": "s663fnrijvqa12se80s8hbra5",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://eauuwgllivcitiyxrquif5lghu.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-sori4vqilngmhcqjifuwsldlfu"
};


export default awsmobile;
