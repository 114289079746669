import React from "react";
import Button from "@material-ui/core/Button";
import _coldStorageTemp from "../components/cs_data/coldStorageTemp.js";
import _dewPointTemp from "../components/cs_data/dewPointTemp.js";
import _ambientTemp from "../components/cs_data/ambientTemp.js";
import _ambientHumid from "../components/cs_data/ambientHumid.js";
import _doorSensorStatus from "../components/cs_data/doorSensorStatus.js";
import _powerSwitchStatus from "../components/cs_data/powerSwitchStatus.js";
import Grid from "@material-ui/core/Grid";

const Home = ({ setSelectedMode, selectedMode }) => {
  function refresh(data) {
    if (data === selectedMode) {
      data = "0";
      setSelectedMode(data);
    } else {
      setSelectedMode(data);
    }
  }
  return (
    <div>
      &nbsp;
      <center>
        <h1>{localStorage.getItem("id")} </h1>
      </center>
      &nbsp;
      <center>
        <Button onClick={() => refresh("1")} variant="contained">
          Refresh
        </Button>
      </center>
      &nbsp;
      <center>
        <h1> Cold Room Conditions: </h1>
      </center>
      &nbsp;
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <_coldStorageTemp />
        <_dewPointTemp />
      </Grid>
      &nbsp;
      <center>
        {" "}
        <h1> Ambient Conditions: </h1>
      </center>
      &nbsp;
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <_ambientTemp />
        <_ambientHumid />
      </Grid>
      &nbsp;
      <center>
        {" "}
        <h1> Door Status: </h1>
      </center>
      &nbsp;
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <_doorSensorStatus />
      </Grid>
      &nbsp;
      <center>
        {" "}
        <h1> Unit Power: </h1>
      </center>
      &nbsp;
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <_powerSwitchStatus />
      </Grid>
    </div>
  );
};

export default Home;
