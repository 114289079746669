import React from "react";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Menu, MenuItem } from "@material-ui/core";
import _coldStorageTemp from "../components/cs_data/coldStorageTemp.js";
import _dewPointTemp from "../components/cs_data/dewPointTemp.js";
import _ambientTemp from "../components/cs_data/ambientTemp.js";
import _ambientHumid from "../components/cs_data/ambientHumid.js";
import _doorSensorStatus from "../components/cs_data/doorSensorStatus.js";
import _powerSwitchStatus from "../components/cs_data/powerSwitchStatus.js";
import _onexRelayStatus from "../components/cs_data/onexRelayStatus";
import _twoxRelayStatus from "../components/cs_data/twoxRelayStatus";
import _threexRelayStatus from "../components/cs_data/threexRelayStatus";
import _fourxRelayStatus from "../components/cs_data/fourxRelayStatus";
import _fivexRelayStatus from "../components/cs_data/fivexRelayStatus";
import _sixxRelayStatus from "../components/cs_data/sixxRelayStatus";
import _sevenxRelayStatus from "../components/cs_data/sevenxRelayStatus";
import _eightxRelayStatus from "../components/cs_data/eightxRelayStatus";
import _batteryVoltage from "../components/cs_data/batteryVoltage";
import _thermalBatteryTemp from "../components/cs_data/thermalBatteryTemp";
import _waterInletTemp from "../components/cs_data/waterInletTemp";
import _wetBulbTemp from "../components/cs_data/wetBulbTemp";
import _graphFourHr from "./cs_graph/graphFourHr";
import _graphTwelveHr from "./cs_graph/graphTwelveHr";
import _graphOneDay from "./cs_graph/graphOneDay";
import _graphOneWeek from "./cs_graph/graphOneWeek";

const Detailed = ({ setSelectedMode, selectedMode }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [graph, setGraph] = useState();
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (id) => {
    if (id === 0) {
      setGraph(_graphFourHr);
    }
    if (id === 1) {
      setGraph(_graphTwelveHr);
    }
    if (id === 2) {
      setGraph(_graphOneDay);
    }
    if (id === 3) {
      setGraph(_graphOneWeek);
    }
    handleClose();
  };

  function refresh(data) {
    if (data === selectedMode) {
      data = "0";
      setSelectedMode(data);
    } else {
      setSelectedMode(data);
    }
  }
  return (
    <div>
      &nbsp;
      <center>
        <h1>{localStorage.getItem("id")} </h1>
      </center>
      &nbsp;
      <center>
        <Button onClick={() => refresh("1")} variant="contained">
          Refresh
        </Button>
      </center>
      &nbsp;
      <center>
        {" "}
        <h1> Relay Status: </h1>
      </center>
      &nbsp;
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <_onexRelayStatus />
        <_twoxRelayStatus />
        <_threexRelayStatus />
        <_fourxRelayStatus />
        <_fivexRelayStatus />
        <_sixxRelayStatus />
        <_sevenxRelayStatus />
        <_eightxRelayStatus />
      </Grid>
      &nbsp;
      <center>
        {" "}
        <h1> Others: </h1>
      </center>
      &nbsp;
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <_batteryVoltage />
        <_thermalBatteryTemp />
        <_waterInletTemp />
        <_wetBulbTemp />
      </Grid>
      &nbsp;
      <center>
        {" "}
        <h1> Graphs: </h1>
      </center>
      &nbsp; &nbsp;
      <>
        <center>
          <Button
            variant="contained"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleOpen}
          >
            Select Graphs
          </Button>
          &nbsp; &nbsp;
        </center>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem onClick={() => handleSelect(0)}>Last 4 Hour</MenuItem>
          <MenuItem onClick={() => handleSelect(1)}>Last 12 Hours</MenuItem>
          <MenuItem onClick={() => handleSelect(2)}>Last 24 Hours</MenuItem>
          <MenuItem onClick={() => handleSelect(3)}>Last 7 Days</MenuItem>
        </Menu>
        {graph}
      </>
    </div>
  );
};

export default Detailed;
